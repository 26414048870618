require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast);
    global.login = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  var _default = {
    name: "login",
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        name: '',
        errText: '',
        fType: ''
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);

      if (vm.$route.query.uuid) {
        fType = vm.$strTool.filterFactoryType(vm.$route.query.uuid);
        fType && vm.$localStorage.setItem('uuid', vm.$route.query.uuid);
      }

      vm.fType = fType;

      if (['yingwo', 'lexing'].indexOf(fType) > -1 && name) {
        vm.name = name;
        vm.$router.replace({
          path: fType == 'lexing' ? "/ryProbe/preSale/list" : "/ryProbe/afterSale/list"
        });
      }
    },
    methods: {
      inputChange: function inputChange() {
        var vm = this;
        var han = /^[\u2E80-\u9FFF]+$/;
        var err = "";

        if (!vm.name) {
          err = "请输入您的名字~";
        }

        if (!han.test(vm.name)) {
          err = "请输入6个字以内中文~";
        }

        if (vm.name == 'Yudada') {
          err = '';
        }

        vm.errText = err;
      },
      login: function login() {
        var vm = this;

        if (vm.errText) {
          _toast.default.fail({
            message: vm.errText || '请输入您的名字',
            forbidClick: true,
            overlay: true,
            duration: 3000
          });

          return;
        }

        var USER_NAME = vm.$config.keys.USER_NAME;
        var uuid = vm.$route.query.uuid || vm.$localStorage.getItem('uuid');
        var fType = vm.$strTool.filterFactoryType(uuid);
        vm.$localStorage.setItem(USER_NAME, vm.name);
        vm.$route.query.uuid && vm.$localStorage.setItem('uuid', uuid);

        if (fType == "yingwo") {
          vm.$router.replace({
            path: "/ryProbe/afterSale/list"
          });
        }

        if (['lexing', 'wangbo'].includes(fType)) {
          vm.$router.replace({
            path: "/ryProbe/preSale/list"
          });
        }
      }
    }
  };
  _exports.default = _default;
});