var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-probe-after-sale-login" }, [
    _c("div", { staticClass: "bg-box" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [
          _vm.fType == "yingwo"
            ? _c("img", {
                attrs: {
                  src: _vm.ossHost + "img/title_after_sale_login.png",
                  alt: ""
                }
              })
            : _vm._e(),
          _vm.fType == "lexing"
            ? _c("img", {
                attrs: {
                  src: _vm.ossHost + "img/title_after_sale_login_lexing.png",
                  alt: ""
                }
              })
            : _vm._e()
        ]),
        _c("div", { staticClass: "login-box" }, [
          _c("div", { staticClass: "header" }, [_vm._v("请输入质检人姓名")]),
          _c("div", { class: { name: true, error: _vm.errText } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              attrs: {
                maxlength: "6",
                type: "text",
                placeholder: "请输入姓名"
              },
              domProps: { value: _vm.name },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  },
                  _vm.inputChange
                ]
              }
            })
          ]),
          _vm.errText
            ? _c("p", { staticClass: "red" }, [_vm._v(_vm._s(_vm.errText))])
            : _vm._e(),
          _c("div", { staticClass: "login-btn", on: { click: _vm.login } }, [
            _vm._v("登录")
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "footer-logo" }, [
      _c("img", { attrs: { src: _vm.ossHost + "img/logo.png", alt: "" } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }